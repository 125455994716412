import React, { useRef, useEffect } from "react"
import Img from "gatsby-image"
import { useSelector } from "react-redux"
import { initVimeoPlayerWithControl, initVimeoPlayer } from "../../utils"
import CTABtn from "../../Button/CTA"
import Explore from "../Home/Explore"
import Join from "../Home/Join"

export default function ArticleOne({ sanity }) {
  const isChinese = useSelector(state => state.lang.locale) === "tc"
  return (
    <div>
      <SectionIntro isChinese={isChinese} />
      <SectionTwo isChinese={isChinese} data={sanity} />
      <SectionThree isChinese={isChinese} />
      <SectionFour isChinese={isChinese} data={sanity} />
      <SectionFive isChinese={isChinese} data={sanity} />
      <SectionSix isChinese={isChinese} data={sanity} />
      <SectionSeven isChinese={isChinese} data={sanity} />
      <SectionNine isChinese={isChinese} />
      <Explore home={sanity.sanityHomePage} />
      <Join home={sanity.sanityHomePage} />
      <CTABtn />
    </div>
  )
}

function SectionIntro() {
  const video = useRef(null)
  useEffect(() => {
    video.current &&
      initVimeoPlayerWithControl({
        selector: video.current,
        desktopVid: "https://vimeo.com/455442638/bfee30d825?background=1",
        mobileVid: null,
      })
  }, [])

  return (
    <section>
      <div className="flex justify-center bg-black">
        <div className="w-5/12 md:w-full">
          <div ref={video}></div>
        </div>
      </div>
    </section>
  )
}

function SectionTwo({ isChinese, data }) {
  return (
    <section className="py-40 md:py-20">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="w-10/12 md:w-full md:px-12">
            <h2 className="quotes md:text-5xl bold-font w-full mb-10 md:mb-20">
              90-Seconds with BMW Professional Vol. 4: BMW Intelligent Personal
              Assistant
            </h2>
            <p className="large-text mb-20 md:mb-12">
              {isChinese
                ? "大家會否遇上因需要操作汽車上的功能，而令手放開軚環或視線離開路面的危險情況呢？"
                : "In order to navigate your car’s control panels, have you ever encountered a dangerous situation, where you need to leave your hands off the wheel, or have your eyes sight away from the road?"}
            </p>
            <p className="large-text mb-20 md:mb-12">
              {isChinese
                ? "第4集90-second with BMW Professional讓你利用90秒時間，了解BMW Intelligent Personal Assistant 智慧型語音助理及Car Caring function座艙關懷程式。BMW專業人員示範智慧型語音助理如何支援行車常用指令，例如尋找興趣點和調節空調溫度等。"
                : "In episode 4 of 90-seconds with BMW Professional, we teach you about BMW’s Intelligent Personal Assistant and Car Caring functions. Our BMW professionals will demonstrate how BMW Intelligent Personal Assistant (IPA) can execute your daily driving instructions, such as searching for points of interest or adjusting the temperature of the air conditioner, etc."}
            </p>
            <p className="large-text mb-12">
              {isChinese
                ? "BMW Intelligent Personal Assistant，一個可以令你透過說話去操作車上部分功能的助手。只要按軚環上的語音指令按鍵，或由司機或前座乘客說出「你好，BMW！ 」，即可以啟動功能，然後說出指令。"
                : 'BMW Intelligent Personal Assistant allows you to operate some functions of your BMW with voice command. To activate the IPA inside your car, press the voice command button on the steering wheel, or the driver or passenger in the front seat can simply say "Hello, BMW.”. The system will respond and will be able to operate some of your car’s features and controls using voice commands.'}
            </p>
            <p className="large-text mb-40">
              {isChinese
                ? "首先，我們示範使用導航功能。"
                : "First, let’s demonstrate the Navigation function."}
            </p>
            <div className="image-container">
              <Img
                fluid={data.articleimg.childImageSharp.fluid}
                className="md:hidden"
              />
              <Img
                fluid={data.articleimgmobile.childImageSharp.fluid}
                className="hidden md:block"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionThree({ isChinese }) {
  return (
    <section className="py-40 md:py-20 bg-brand-light-grey">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="w-10/12 md:w-full px-12">
            <div className="flex md:flex-col">
              <div className="w-1/2 md:w-full pr-20 md:pr-0">
                <div className="flex justify-end">
                  <hr className="w-64 md:w-full h-1 bg-black mb-32 md:hidden" />
                </div>
                <h3 className="quotes quotes--sm md:text-4xl bold-font text-right md:text-left md:mb-20">
                  {isChinese
                    ? "「你好，BMW！帶我去寶馬荃灣陳列室。」"
                    : '"Hello, BMW.  Take me to the BMW Tsuen Wan showroom."'}
                </h3>
              </div>
              <div className="w-1/2 md:w-full pl-20 md:pl-0">
                <p className="large-text light-font">
                  {isChinese
                    ? "你亦可以講出你想尋找的地點類型，例如便利店，停車場或油站等等。你亦可以直接講出「我想找日本餐廳。」，導航系統便會協助你搜尋附近相應的地點。"
                    : 'You can also say the type of location you want to search, such as convenient stores, parking lots or gasoline stations, etc.  You can also say, "I want to go to a Japanese restaurant.”  The navigation system will assist you in searching for nearby Japanese restaurants.'}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionFour({ isChinese, data }) {
  return (
    <section className="pt-40 xxl:pb-40 md:py-10">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="w-10/12 md:w-full px-12">
            <div className="image-container mb-32 md:mb-20">
              <Img
                fluid={data.articleimg2.childImageSharp.fluid}
                className="md:hidden"
              />
              <Img
                fluid={data.articleimg2mobile.childImageSharp.fluid}
                className="hidden md:block"
              />
            </div>
            <div className="w-full">
              {isChinese ? (
                <>
                  <p className="large-text mb-20">
                    工作了一整天，駕車回家時又遇上交通擠塞，令人感覺煩躁和疲累。
                  </p>
                  <p className="large-text">此時，BMW亦可以協助你。</p>
                </>
              ) : (
                <p className="large-text">
                  After a long day of work, if you get caught in traffic
                  congestion while driving home, you might be exhausted and
                  tired. At this time, your BMW IPA can also assist you.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionFive({ isChinese, data }) {
  return (
    <section className="py-40 md:py-10">
      <Img
        fluid={data.articleimg3.childImageSharp.fluid}
        className="md:hidden"
      />
      <Img
        fluid={data.articleimg3mobile.childImageSharp.fluid}
        className="hidden md:block"
      />
      <div className="article-container">
        <div className="flex justify-center">
          <div className="w-10/12 md:w-full xxl:px-40 px-20 md:px-0">
            <div className="text-center">
              <div className="flex justify-center">
                <hr className="w-64 md:w-full h-1 bg-black mt-32 mb-20 md:hidden" />
              </div>
              <h3 className="quotes--sm md:text-4xl bold-font text-center leading-relaxed md:leading-normal my-20">
                {isChinese ? "「我好累。」" : "Just say, “I’m exhausted.”"}
              </h3>
              <h3 className="quotes--sm md:text-4xl text-center leading-relaxed md:leading-normal md:mt-20">
                {isChinese
                  ? "BMW會自動為你開啟活力模式，調節氣氛燈顏色，車廂冷氣溫度及開啟天窗窗簾。"
                  : "Your BMW will automatically switch on the vitality mode by adjusting the color of the Ambient light, lower down the temperature of the air-conditioning and open the shade of sunroof."}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionSix({ isChinese, data }) {
  const video = useRef(null)

  useEffect(() => {
    video.current &&
      initVimeoPlayer({
        selector: video.current,
        desktopVid: "https://vimeo.com/456108785?background=1",
        mobileVid: null,
      })
  }, [])
  return (
    <section className="pt-40 md:pt-20">
      <div className="flex md:flex-col">
        <div className="w-1/2 md:w-full flex bg-brand-grey md:order-2">
          <div className="self-center px-20 md:py-20 md:px-12 text-white">
            <p className="large-text bold-font font-extrabold leading-relaxed mb-20">
              {isChinese
                ? "「你好BMW！將後排冷氣溫度調較至18度。」"
                : "“Hello BMW. Adjust the rear-seat air temperature to 18 degrees.“"}
            </p>
            <p className="large-text light-Îfont leading-relaxed">
              {isChinese
                ? "智慧型語音助理除了能夠協助駕駛者的日常操作外，更能夠控制部分後座乘客的設備。"
                : "The Intelligent Personal Assistant does not only assist drivers with daily driving instructions, but also control part of the settings for rear passengers"}
            </p>
          </div>
        </div>
        <div className="w-1/2 md:w-full md:order-1">
          <div ref={video} />
        </div>
      </div>
      <div className="flex md:flex-col">
        <div className="w-1/2 md:w-full">
          <Img fluid={data.articleimg5.childImageSharp.fluid} />
        </div>
        <div className="w-1/2 md:w-full flex">
          <div className="self-center px-20 md:py-20 md:px-12">
            <p className="large-text bold-font font-extrabold leading-relaxed mb-20">
              {isChinese
                ? "小貼士：你還可以為你的BMW改名，更改喚醒詞。"
                : "Tips: You can also change the name of your BMW and change the personal activation word."}
            </p>
            <p className="large-text light-font leading-relaxed">
              {isChinese
                ? "「你好，BMW！我想幫你改名。」"
                : "“Hello, BMW! Change the activation word.“"}
            </p>
            <p className="large-text light-font leading-relaxed">
              {isChinese ? "「寶馬3系」" : "“BMW THE 3”"}
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionSeven({ isChinese, data }) {
  return (
    <section className="pb-40 md:pb-20">
      <div className="image-container">
        <Img fluid={data.articleimg4.childImageSharp.fluid} />
      </div>
      <div className="article-container">
        <div className="flex justify-center">
          <div className="w-10/12 md:w-full px-12">
            <div className="text-center mt-40">
              <p className="large-text text-center leading-relaxed mb-20">
                {isChinese
                  ? "BMW智慧型語音助理讓你以全新、簡單的方式與您的BMW溝通，協助你執行部分行車相關的使用功能，大大提升便利性。"
                  : "The BMW Intelligent Personal Assistant allows you to interact with your BMW like never before – in a brand new and simple way. It assists you in performing some driving-related functions which greatly improve your driving experience. It also helps to keep the driver's eyes on the road for some added safety."}
              </p>
              <p className="text-3xl light-font text-center leading-relaxed">
                {isChinese
                  ? "( BMW智慧型語音助理及座艙關懷程式只適用於部分配備BMW Live Cockpit Professional之指定型號。)"
                  : "(The BMW Intelligent Voice Assistant and Car Caring App are only applicable to certain designated models equipped with BMW Live Cockpit Professional)."}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionNine({ isChinese }) {
  return (
    <section className="py-40 my-40 md:py-20 md:my-20 bg-brand-light-grey">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="w-10/12 md:w-full px-12">
            <div className="flex justify-center md:flex-col">
              <div className="w-1/2 md:w-full">
                {isChinese ? (
                  <p className="large-text leading-relaxed mb-20">
                    緊貼BMW資訊，為現在及未來做好規劃，開拓寬闊通道。更多資訊陸續為您送上！
                  </p>
                ) : (
                  <p className="large-text leading-relaxed mb-20">
                    Empower yourself with information. Stay tuned for our latest
                    updates about <b>Along with BMW</b> and learn more about our
                    BMW experience.
                  </p>
                )}
                <div className="flex justify-center">
                  <hr className="w-40 md:w-full h-1 bg-black md:hidden" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
